import React, { useState } from 'react';
import Slider from 'react-slick';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../../components';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';

function AlauneSlider({ podcasts }) {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [invalid, setInvalid] = useState(false);
	function SampleNextArrow(props) {
		const { className, style, onClick } = props;
		return (
			<IoChevronForwardOutline
				className={`${className} arrowAlauneSlider`}
				style={{
					...style,
					display: 'block',
					color: 'white',
					fontSize: '30px',
					zIndex: 1,
					height: '100%',
					borderRadius: '0 10px 10px 0',
					width: '50px',
					right: '0px',
				}}
				onClick={onClick}
			/>
		);
	}

	function SamplePrevArrow(props) {
		const { className, style, onClick } = props;
		return (
			<IoChevronBackOutline
				className={`${className} arrowAlauneSlider`}
				style={{
					...style,
					display: 'block',
					color: 'white',
					fontSize: '30px',
					zIndex: 2,
					height: '100%',
					borderRadius: '10px 0 0 10px',
					width: '50px',
					left: '3px',
				}}
				onClick={onClick}
			/>
		);
	}

	var settings = {
		dots: false,
		infinite: true,
		slidesToShow: 2,
		slidesToScroll: 1,
		autoplay: !isMobile,
		autoplaySpeed: 3000,
		pauseOnHover: true,
		draggable: isMobile,
		arrows: !isMobile,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1,
				},
			},
		],
	};

	const handleOnClick = (idseries) => {
		navigate(`/podcast/${idseries}`);
	};
	return (
		<div className="slider-container">
			<Slider {...settings}>
				{podcasts.map((item, index) => (
					<div
						key={index}
						className="responsive-image-container-alaune"
						onClick={() => handleOnClick(item.idseries)}
					>
						<Loading
							size={30}
							color="red"
							conClass="podcastAlauneLoading"
							style={{
								opacity: loading && !invalid ? 1 : 0,
								position: 'absolute',
								top: '50%',
								height: '30px',
								zIndex: '99',
							}}
						/>
						<img
							src={item.imageUrl}
							alt={`Slide ${index}`}
							className="responsive-image-alaune"
							onLoad={() => setLoading(false)}
							onError={() => setInvalid(true)}
						/>
					</div>
				))}
			</Slider>
		</div>
	);
}

export default AlauneSlider;
