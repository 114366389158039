import React, { useEffect, useState } from 'react';
import './style.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import translate from '../../translation';
import { useSelector, useDispatch } from 'react-redux';
import { scrollTop } from '../../helpers/behavior';
import { Categories, Top, AlauneSlider } from './childs';
import { SearchBar, Loading, Empty } from '../../components';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { getPodcastsAlaune, getPodcastsTop, getPodcastsCategories } from '../../store/actions/podcasts';
import { SET_SERIES_PODCAST, SET_CURRENT_PODCAST, SET_CURRENT_RADIO } from '../../store/actions/constants';

export default function Podcasts() {
	const navigate = useNavigate();
	const { podcasts, podcastsTop, podcastsCategories } = useSelector((state) => state.podcasts);
	const [slicedPodcastsTop, setSlicesPodcastsTop] = useState();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		scrollTop();
	}, []);

	useEffect(() => {
		dispatch({ type: SET_SERIES_PODCAST, payload: [] });
		dispatch({ type: SET_CURRENT_PODCAST, payload: {} });
		dispatch({ type: SET_CURRENT_RADIO, payload: {} });
	}, [dispatch]);

	useEffect(() => {
		const asyncProcess = () => {
			setLoading(true);
			dispatch(getPodcastsAlaune());
			setLoading(false);
		};
		asyncProcess();
	}, [dispatch]);

	useEffect(() => {
		const asyncProcess = () => {
			dispatch(getPodcastsTop());
		};
		asyncProcess();
	}, [dispatch]);

	useEffect(() => {
		const asyncProcess = () => {
			dispatch(getPodcastsCategories());
		};
		asyncProcess();
	}, [dispatch]);
	useEffect(() => {
		if (podcastsTop && podcastsTop.length > 0) {
			const slicedPodcasts = podcastsTop.map((obj) => ({
				...obj,
				series: obj.series.slice(0, 10),
			}));

			setSlicesPodcastsTop(slicedPodcasts);
		}
	}, [podcastsTop]);
	const onClick = () => {
		navigate('/radios');
	};

	return (
		<div id="podcastPage">
			<Helmet>
				<title>{`Radioplayer : Radio & Podcast en streaming`}</title>
				<meta name={translate('descripton')} content={translate('description_all')} />
				<meta name="robots" content={'radio, streaming, podcast, gratuit, mp3, aac'} />
			</Helmet>

			<section className="section0">
				<Container>
					<Row>
						<Col md={{ span: 8, offset: 2 }} className="text-md-center">
							<h1>{translate('Vos podcasts préférés sont')}</h1>
							<SearchBar />
							<Button onClick={onClick} className="allRadios" variant="secondary">
								{translate('Toutes les radios')}
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="section1 container">
				<h2 className="section1Title ms-3 ms-md-4">{translate('podcastSection1PodcastsTitle')}</h2>

				{loading ? (
					<Loading color="#cf2345" size={50} conClass="loading" />
				) : podcasts.length === 0 ? (
					<Empty text={translate('Aucun podcast disponible')} />
				) : (
					<AlauneSlider podcasts={podcasts} />
				)}
			</section>
			<section className="section2 container">
				<div className="podcastsTop-container">
					{loading ? (
						<Loading color="#cf2345" size={50} conClass="loading" />
					) : !slicedPodcastsTop ? (
						<Empty text={translate('Aucun podcast disponible')} />
					) : (
						<Top podcasts={slicedPodcastsTop} categories={podcastsCategories} />
					)}
				</div>
			</section>
			<section className="section3 container">
				<h2 className="section3Title ms-3 ms-md-4">{translate('homeSection1CatsTitle')}</h2>
				<div>
					<div className="categoryContainer mx-2 mx-md-5 pb-3 ">
						<Categories categories={podcastsCategories} />
					</div>
				</div>
			</section>
		</div>
	);
}
