import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import Loading from '../Loading';

const PodcastItemCat = ({ items }) => {
	const [loading, setLoading] = useState(true);
	const [invalid, setInvalid] = useState(false);
	const isClient = typeof window === 'object';
	const navigate = useNavigate();
	const getSize = useCallback(() => {
		return isClient ? window.innerWidth : undefined;
	}, [isClient]);

	const [windowSize, setWindowSize] = useState(getSize);

	useEffect(() => {
		if (!isClient) {
			return false;
		}
		function handleResize() {
			setWindowSize(getSize());
		}
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [windowSize, getSize, isClient]);
	const handleOnClick = (idseries) => {
		navigate(`/podcast/${idseries}`);
	};
	return items.map((item, index) => (
		<div className="podcastItemGroup" key={item?.id?.toString() + 'podcastItemGroup' + index}>
			<div
				id={`radio${item?.rpID}`}
				name="redirectButton"
				className={`podcastItem mx-2`}
				style={{ backgroundImage: `url(${item?.image})` }}
				onClick={(e) => handleOnClick(item.id)}
			>
				<Loading
					size={30}
					color="red"
					conClass="podcastItemLoading"
					style={{ opacity: loading && !invalid ? 1 : 0 }}
				/>
				<img
					alt={item.nom}
					src={item?.image}
					onLoad={() => setLoading(false)}
					onError={() => setInvalid(true)}
					style={{ opacity: 1 }}
				/>
			</div>
			{windowSize < 769 ? (
				<div className="podcastListingContent ms-2">
					<div>{item.nom}</div>
				</div>
			) : null}
		</div>
	));
};

export default PodcastItemCat;
