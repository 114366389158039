import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './style.css';
import { SET_PLAYER } from '../../store/actions/constants';
import { Button, Col } from 'react-bootstrap';
import { IoPlay } from 'react-icons/io5';
import { TiMediaPause } from 'react-icons/ti';

const PodcastSerie = ({ item }) => {
	const { playing, stream: playedPodcast } = useSelector((state) => state.player);
	const dispatch = useDispatch();
	const handleOnPlay = () => {
		const payload = {
			playing: !(playing && playedPodcast.url === item.audio_url),
			stream: {
				nom: item.mediumName,
				description: item.longName,
				rpID: item.rpID,
				image: item.image,
				stream: item.audio_url,
				url: item.audio_url,
			},
			playedSeconds: 0,
			deltaTime: 0,
			userClick: true,
			isRadio: false,
		};

		dispatch({ type: SET_PLAYER, payload: payload });
	};
	return (
		<Col md={{ span: 10, offset: 1 }}>
			<div className="episodeItem">
				<div className="episodeItemCard bg-white" style={{ backgroundImage: `url(${item?.image})` }} />
				<div className="episodeItemContent">
					<h2>{item.longName}</h2>
					<p>{item.longDescription}</p>
				</div>
				<Button className="btn-play-mini" onClick={handleOnPlay}>
					{playing && playedPodcast.url === item.audio_url ? (
						<TiMediaPause color="#000" size={20} />
					) : (
						<IoPlay color="#000" size={20} />
					)}
				</Button>
			</div>
		</Col>
	);
};

export default PodcastSerie;
