import React, { useEffect, useState } from 'react';
import './style.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import translate from '../../translation';
import { Empty, FeedItem, Loading } from '../../components';
import { Helmet } from 'react-helmet';
import { getArticles } from '../../store/actions/feed';

const Feed = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [loadingMore, setLoadingMore] = useState(false);
	const [page, setPage] = useState(0);
	const { allFeeds, isLastPage } = useSelector((state) => state.feed);

	useEffect(() => {
		const asyncProcess = async () => {
			await dispatch(getArticles());
			setLoading(false);
		};
		asyncProcess();
	}, [dispatch]);

	const onClickGetMore = () => {
		(async () => {
			setLoadingMore(true);
			await dispatch(getArticles(page + 1));
			setPage(page + 1);
			setLoadingMore(false);
		})();
	};

	return (
		<div id="feedPage">
			<Helmet>
				<title>{`Radioplayer : Radio & Podcast en streaming`}</title>
				<meta name={translate('descripton')} content={translate('description_all')} />
				<meta name="robots" content={'radio, streaming, podcast, gratuit, mp3, aac'} />
			</Helmet>
			<section className="section1">
				<Container>
					<h1>{translate('Notre actualité')} </h1>
				</Container>
			</section>
			<section className="section2">
				<Container>
					<Row>
						{loading && page === 1 ? (
							<Loading color="#cf2345" size={50} conClass="loading" />
						) : allFeeds.length === 0 ? (
							<Empty text={translate('Aucune article disponible')} />
						) : (
							allFeeds.map((item) => (
								<Col md="4" key={item?.id?.toString()}>
									<FeedItem item={item} />
								</Col>
							))
						)}
					</Row>
					<Row className="text-center">
						{loadingMore && page > 1 && <Loading color="#cf2345" size={30} conClass="loadingMore" />}
						{!isLastPage && (
							<Button variant="dark" onClick={onClickGetMore} disabled={loading}>
								{translate('Afficher plus')}
							</Button>
						)}
					</Row>
				</Container>
			</section>
		</div>
	);
};

export default Feed;
