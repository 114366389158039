import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import './style.css';
import { Helmet } from 'react-helmet';
import { scrollTop } from '../../helpers/behavior';

const Policy = () => {
	useEffect(() => {
		scrollTop();
	}, []);

	return (
		<div id="footerPage">
			<Helmet>
				<title>{`Radioplayer : Radio & Podcast en streaming`}</title>
				<meta
					name="description"
					content="Retrouvez les radios françaises et les podcasts sur Radioplayer France. Plus de 800 radios et des milliers de podcasts à votre disposition."
				/>
				<meta name="robots" content={'radio, streaming, podcast, gratuit, mp3, aac'} />
			</Helmet>
			<div>
				<section className="section1">
					<Container>
						<h1>Politique de confidentialité</h1>
					</Container>
				</section>
				<section className="section2">
					<Container>
						<h3>POLITIQUE DE CONFIDENTIALITE</h3>
						<p>
							Lors de l’utilisation du site (ci-après, le «&nbsp;Site&nbsp;»), la société COSMOS, Société
							par Actions Simplifiée au capital de 90,000 Euros, immatriculée au Registre du Commerce et
							des Sociétés de Paris sous le numéro 888 335 759 et dont le siège social est situé 14 rue
							Lübeck 75116 à Paris (ci-après, « COSMOS »), agit en qualité de responsable de traitement,
							et est susceptible de traiter des données à caractère personnel des utilisateurs du Site
							(ci-après, «&nbsp;Vous&nbsp;», «&nbsp;Vos&nbsp;», «&nbsp;Votre&nbsp;»). En application de la
							loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés dite «
							Informatique et libertés » dans sa dernière version et le règlement 2016/679 du 27 avril 216
							relatif à la protection des données dit «&nbsp;RGPD&nbsp;», la présente notice vise à Vous
							informer des modalités de collecte, de traitement et d’utilisation des données à caractère
							personnel et de Vos droits en matière de protection des données à caractère personnel.
						</p>
						<h3>Bases légales</h3>
						<p>
							La collecte et le traitement de Vos données à caractère personnel pour les finalités
							décrites au sein de la présente Politique de confidentialité ont pour base légale&nbsp;:
						</p>
						<ul>
							<li>Votre consentement&nbsp;;</li>
							<li>L’exécution des Conditions Générales d’Utilisation (CGU).</li>
						</ul>
						<p>
							Lors de l’utilisation du Site Vous avez accepté les CGU et la politique de confidentialité
							qui en fait partie intégrante, Vous acceptez ainsi que Nous traitions des données à
							caractère personnel aux fins ci-dessous développées.
						</p>
						<p>
							Lorsque le traitement est fondé sur le consentement, Vous êtes informé que Vous pouvez
							retirer celui-ci à tout moment conformément à la règlementation applicable.
						</p>
						<h3>Données collectées</h3>
						<p>
							Dans le cadre de l’utilisation du Site, plusieurs catégories de données à caractère
							personnel peuvent être collectées. Ces données personnelles peuvent également être corrélées
							avec des données non personnelles, collectées par le biais de cookies ou traceurs.
						</p>
						<p>COSMOS collecte les types de données suivantes&nbsp;:</p>
						<ul>
							<li>
								Votre nom et adresse e-mail. Ces données sont collectées directement auprès de Vous et
								avec Votre consentement, lors de l’envoi d’un message via notre page
								«&nbsp;Contact&nbsp;». Sans ces données, le service lié à cette collecte ne pourra pas
								être assuré ;
							</li>
						</ul>
						<ul>
							<li>
								Les données de connexion (ex : date et heure de connexion, durée de connexion, pages
								vues) collectées par le biais de cookies ou traceurs&nbsp;;
							</li>
							<li>
								Les données de navigation (ex&nbsp;: adresse IP du terminal, identifiant de session).
							</li>
						</ul>
						<h3>Finalités</h3>
						<p>COSMOS traite les données à caractère personnel en tout ou partie aux fins de&nbsp;:</p>
						<ul>
							<li>
								Vous permettre de naviguer sur le Site et d’adapter les contenus pour améliorer la
								navigation&nbsp;;
							</li>
							<li>Répondre à une demande de contact sur le Site&nbsp;;</li>
							<li>Analyser l’audience et la fréquentation du Site ;</li>
						</ul>
						<h3>Destinataires</h3>
						<p>Ces données sont destinées&nbsp;:</p>
						<ul>
							<li>
								Aux personnels de COSMOS habilités à en prendre connaissance en raison de leurs
								fonctions&nbsp;;
							</li>
							<li>
								Aux sous-traitants éventuels en matière de prestations techniques (notamment
								l’hébergement des données)&nbsp;;
							</li>
						</ul>
						<p>
							Par ailleurs, les données pourront être divulguées en application d’une loi, d’un règlement
							ou en vertu d’une décision d’une autorité réglementaire ou judiciaire compétente.
						</p>
						<h3>Durées de conservation</h3>
						<p>
							COSMOS ne conserve les données à caractère personnel que pour la durée nécessaire et
							proportionnée à la finalité pour laquelle elles ont été collectées.
						</p>
						<p>
							Les choix relatifs aux cookies et traceurs sont conservés pour une durée limitée
							conformément à l’article 7 de la présente politique de confidentialité.
						</p>
						<p>
							Au-delà de la période concernée, Vos données seront supprimées. Toutefois, COSMOS peut
							conserver certaines données à caractère personnel afin de respecter les obligations légales
							et/ou règlementaires auxquelles COSMOS est soumise, ou encore si cela est nécessaire afin de
							permettre à COSMOS d’exercer un droit ou se défendre en justice.
						</p>
						<h3>Lieu d’hébergement des données</h3>
						<p>
							Les Données que COSMOS collecte sont conservées et hébergées au sein de l’Union européenne.
							Néanmoins, les Données sont susceptibles d’être transférées dans des pays situés en dehors
							de l’Union européenne. Ce transfert aura lieu dans le respect de la protection de Vos
							données et conformément à la règlementation applicable.
						</p>
						<h3>Droits</h3>
						<p>
							Conformément aux dispositions de la loi «&nbsp;Informatique et libertés » dans sa dernière
							version et du RGPD, Vous bénéficiez d’un droit d’accès, d’interrogation concernant Vos
							données et leur traitement, de rectification, de portabilité et d’effacement de celles-ci ou
							encore de limitation du traitement. Vous pouvez, pour des motifs légitimes, Vous opposer au
							traitement des données Vous concernant. Vous avez le droit de retirer, à tout moment, Votre
							consentement donné pour le traitement de Vos données basé sur ce fondement légal.
						</p>
						<p>
							Vous disposez également du droit de donner des directives sur la conservation, l’effacement
							et la communication de Vos données à caractère personnel après Votre décès.
						</p>
						<p>
							Vous pouvez, sous réserve de la production d’un justificatif d’identité valide, exercer Vos
							droits contactant COSMOS à l’adresse suivante&nbsp;: COSMOS – 14 rue de Lübeck 75116 Paris
							ou via l’adresse mail suivante&nbsp;:&nbsp;applications@cosmos.radio En cas de litige sur
							l’exercice de Vos droits, Vous avez la faculté d’introduire une réclamation auprès de la
							Commission Nationale de l’Informatique et des Libertés.
						</p>
						<h3>Cookies</h3>
						<p>
							COSMOS implante sur Votre ordinateur des données de trafic et des fichiers de cookies aux
							fins de procéder, en interne, à des analyses de fréquentation de nos pages d’informations
							afin d’en améliorer le contenu ainsi qu’à établir des données statistiques (pages
							consultées, heures de consultation, etc…).
						</p>
						<p>COSMOS utilise les cookies suivants&nbsp;:</p>
						<figure class="wp-block-table">
							<table>
								<tbody>
									<tr>
										<td>
											<strong>Type de Cookies</strong>
										</td>
										<td>
											<strong>Description</strong>
										</td>
										<td>
											<strong>Finalités</strong>
										</td>
									</tr>
									<tr>
										<td>Cookies nécessaires</td>
										<td>
											Ces cookies sont indispensables afin de permettre la navigation sur les
											sites internet et utiliser leurs fonctionnalités&nbsp;
										</td>
										<td>
											– utiliser les principales fonctionnalités du Site&nbsp;; – mémoriser
											certaines informations de session ;
										</td>
									</tr>
									<tr>
										<td>Cookies de mesure d’audience&nbsp;et de performance</td>
										<td>
											Ces cookies permettent de recueillir des renseignements sur la manière dont
											Vous utilisez un site internet et d’identifier les pages internet que Vous
											visitez.
										</td>
										<td>
											– établir des statistiques sur le Site ; – évaluer la performance et
											l’utilisation du Site&nbsp;; – permettre à COSMOS d’améliorer le Site et
											l’expérience utilisateur.
										</td>
									</tr>
									<tr>
										<td>Autres Cookies de tiers.</td>
										<td>Ces cookies peuvent être déposés par des sociétés tierces.</td>
										<td>Google Analytics Facebook</td>
									</tr>
								</tbody>
							</table>
						</figure>
						<p>
							COSMOS Vous informe que Vous pouvez choisir vos préférences à l’implantations de ces
							cookies, à tout moment, en cliquant sur le bouton&nbsp;«&nbsp;Afficher toutes les
							utilisations prévues&nbsp;» du bandeau d’information sur la page d’accueil du Site ou sur le
							lien en bas de page.
						</p>
						<p>COSMOS Vous informe que&nbsp;:</p>
						<ul>
							<li>
								Les outils techniques permettent de suivre les performances du Site et des applications
								et de détecter des problèmes.
								<ul>
									<li>
										Les outils d’analyse permettent de consulter les statistiques d’audiences comme
										par exemple le nombre de visites ou les pages consultées afin de proposer un
										contenu pertinent.
									</li>
								</ul>
							</li>
						</ul>
						<p>
							Dans le cas où Vous acceptez ou refusez l’utilisation des cookies, Vos choix seront
							conservés pour une durée maximale de six (6) mois conformément à la règlementation
							applicable.
						</p>
						<h3>Sécurité</h3>
						<p>
							Soucieux de garantir la sécurité de Vos données, COSMOS a notamment adopté les mesures
							suivantes pour éviter que les données à caractère personnel ne soient déformées, endommagées
							ou que des tiers non autorisés puissent y accéder&nbsp;:
						</p>
						<ul>
							<li>
								La protection de l’accès physique aux locaux dans lesquels sont conservées les données
								(bâtiment sécurisé)&nbsp;;
							</li>
							<li>
								La coopération avec des prestataires respectant la confidentialité et la sécurité des
								données traitées&nbsp;;
							</li>
							<li>La réalisation des traitements sur un réseau interne dédié non-relié à internet.</li>
						</ul>
						<p>
							Des liens présents sur le Site peuvent Vous renvoyer vers des sites extérieurs. COSMOS Vous
							invite à prendre connaissance des CGU en matière de liens hypertextes.
						</p>
						<p>
							Les politiques de protection des données à caractère personnel de ces sites peuvent être
							différentes de la présente politique.
						</p>
						<p>
							En conséquence, il est recommandé de prendre connaissance de la politique de protection des
							données personnelles de chacun des sites concernés. En tout état de cause, la responsabilité
							de COSMOS ne saurait être recherchée dans le cas où le contenu de l’un des sites concernés
							contreviendrait aux dispositions légales et réglementaires en vigueur.
						</p>
						<h3>Mise à jour de la présence politique de confidentialité</h3>
						<p>
							Du fait de la poursuite de la navigation sur le Site, Vous êtes présumé avoir lu et accepté
							la présente politique de confidentialité.
						</p>
						<p>
							La présente politique relative aux données à caractère personnel est susceptible d’être
							modifiée ou aménagée à tout moment.
						</p>
						<p>
							Vous êtes invité à la consulter régulièrement. Pour plus d’informations pratiques sur
							l’exercice de vos droits, COSMOS Vous recommande de consulter le site de la Commission
							Nationale Informatique et Libertés, et plus particulièrement la
							page&nbsp;https://www.cnil.fr/fr/comprendre-vos-droits
						</p>
					</Container>
				</section>
			</div>
		</div>
	);
};

export default Policy;
