import axios from 'axios';
import { username, password, base_url } from '../config/keys';
import { Base64 } from 'js-base64';

const instance = axios.create({
	baseURL: base_url,
});
instance.defaults.headers.common['Authorization'] = `Basic ${Base64.btoa(`${username}:${password}`)}`;
//instance.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export default instance;
