import { Button } from 'react-bootstrap';
import React from 'react';
import { InputRange } from '../..';
import { VolumeIcon, VolumeOffIcon } from '../../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setPlayer } from '../../../store/actions/player';

const Volume = () => {
	const dispatch = useDispatch();
	const { muted, volume } = useSelector((state) => state.player);

	const handleVolumeChange = (e) => {
		dispatch(setPlayer({ volume: parseFloat(e.target.value) }));
	};

	const toggleVolume = () => {
		dispatch(setPlayer({ muted: !muted }));
	};

	return (
		<div className="volumeCon">
			<Button onClick={toggleVolume} className="volumeBtn" variant="link">
				{!muted ? <VolumeIcon size={22} color="#cf2345" /> : <VolumeOffIcon size={22} color="#cf2345" />}
			</Button>
			<InputRange
				className="volume"
				type="range"
				min={0}
				max={1}
				step="any"
				value={volume}
				onChange={handleVolumeChange}
				onInput={handleVolumeChange}
			/>
		</div>
	);
};

export default Volume;
