import React from 'react';
import PodcastsTopSlider from '../../../components/PodcastsTopSlider';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IoChevronForwardOutline } from 'react-icons/io5';

export default function PodcastsTop({ podcastsTop }) {
	const navigate = useNavigate();

	const onClick = (e) => {
		navigate(`/podcasts/`);
	};

	return (
		<Container className="carousel-container" key={podcastsTop?.name?.toString()}>
			<h2 className="section2Title mt-5 ms-md-4" onClick={onClick} data-id={podcastsTop?.name?.toString()}>
				{podcastsTop.name} <IoChevronForwardOutline data-id={podcastsTop?.name?.toString()} />
			</h2>
			<PodcastsTopSlider podcasts={podcastsTop?.series} />
		</Container>
	);
}
