import React from 'react';
import Slider from 'react-slick';
import { RadioItem } from '../../../components';
import { RadioItemGroup } from '../../../components';

const { REACT_APP_S3_URL } = process.env;

const settings = {
	infinite: false,
	speed: 300,
	slidesToShow: 3,
	slidesPerRow: 1,
	slidesToScroll: 3,
	rows: 4,
};

const RadiosIndesGroupId = 9;
const FranceBleuGroupId = 30;

const Features = ({ featured }) => {
	const { innerWidth: width } = window;
	var isGroupIndeSpawn = false; // utilisé pour gérer les cas des radios de groupe
	var isGroupFranceBleuSpawn = false; // utilisé pour gérer les cas des radios de groupe

	const featuredElements = featured.map(function (item) {
		var isGroup = false; // utilisé pour gérer les cas des radios de groupe
		var currentCategory = 0; // utilisé pour gérer les cas des radios de groupe
		var currentImageUrl = ''; // utilisé pour gérer les cas des radios de groupe

		isGroup = item.categories.includes(RadiosIndesGroupId) || item.categories.includes(FranceBleuGroupId);
		currentCategory = !isGroup
			? 0
			: item.categories.includes(RadiosIndesGroupId)
			? RadiosIndesGroupId
			: FranceBleuGroupId;

		if (isGroup) {
			// Cas spécial ou c'est une radio faisant partie d'un groupe mais pas vraiment, pour l'instant géré en dur
			if (currentCategory === RadiosIndesGroupId) {
				if (!isGroupIndeSpawn) {
					isGroupIndeSpawn = true;
					currentImageUrl = `${REACT_APP_S3_URL}group-logos/lesindesradios.png`;
					return (
						<RadioItemGroup
							key={item?.rpID?.toString()}
							item={item}
							categorieName={'Les_Indés_Radios'}
							imageUrl={currentImageUrl}
						/>
					);
				} else {
					return <RadioItem key={item?.rpID?.toString()} item={item} />;
				}
			}

			if (!isGroupFranceBleuSpawn && currentCategory === FranceBleuGroupId) {
				isGroupFranceBleuSpawn = true;
				currentImageUrl = `${REACT_APP_S3_URL}group-logos/francebleu.png`;
				return (
					<RadioItemGroup
						key={item?.rpID?.toString()}
						item={item}
						categorieName={'France_Bleu'}
						imageUrl={currentImageUrl}
					/>
				);
			}
		} // Cas normal d'une radio
		else {
			return <RadioItem key={item?.rpID?.toString()} item={item} />;
		}
		return <RadioItem key={item?.rpID?.toString()} item={item} />;
	});
	if (width <= 768) {
		return <Slider {...settings}>{featuredElements}</Slider>;
	} else {
		return featuredElements;
	}
};

export default Features;
