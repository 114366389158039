import React from 'react';
import { CategoryItem } from '../../../components';

/**
 * div contenant les boutons de catégories de radios
 * @param {array} categories
 * @returns JSX
 */
function CategoriesHome({ categories }) {
	return (
		<div className="homeCats">
			{categories.map((item) => (
				<CategoryItem key={item?.id?.toString()} item={item} />
			))}
		</div>
	);
}

export default CategoriesHome;
