import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import './style.css';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { PodcastCategory, PodcastItemCat } from '../../components';
import translate from '../../translation';
import { getPodcastsCategories, getPodcastsGenre } from '../../store/actions/podcasts';
import { useDispatch } from 'react-redux';
import { scrollToElement } from '../../helpers/behavior';
import Slider from 'react-slick';

export default function PodcastsCategories() {
	const { podcastsCategories, genrePodcasts } = useSelector((state) => state.podcasts);
	const [morePodcasts, setMorePodcasts] = useState(false);
	const [thisCategorie, setThisCategorie] = useState();
	const { catName } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isClient = typeof window === 'object';
	const podcastToShow = genrePodcasts.length > 25 && !morePodcasts ? genrePodcasts.slice(0, 25) : genrePodcasts;

	const settings = {
		infinite: false,
		speed: 300,
		slidesToShow: 2,
		slidesPerRow: 1,
		slidesToScroll: 1,
		arrows: false,
		swipeToSlide: true,
	};
	const sliderRef = useRef();

	sliderRef?.current?.slickGoTo(0);
	const getSize = useCallback(() => {
		return isClient ? window.innerWidth : undefined;
	}, [isClient]);

	const [windowSize, setWindowSize] = useState(getSize);

	// Chargement des radios en fonction de la catégorie selectionnée
	useEffect(() => {
		if (catName) {
			scrollToElement('#categoryPodcastPage .section2 .podcastsBlockTitle');
		}
	}, [catName, dispatch]);

	useEffect(() => {
		const currentCategorieShown = podcastsCategories.find(
			(categorie) => categorie.nom.toString() === catName?.replaceAll('_', ' ')
		);
		if (currentCategorieShown) setThisCategorie(currentCategorieShown.nom);
	}, [catName, podcastsCategories]);

	useEffect(() => {
		if (!isClient) {
			return false;
		}
		function handleResize() {
			setWindowSize(getSize());
		}
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [windowSize, getSize, isClient]);
	useEffect(() => {
		const asyncProcess = () => {
			const catId = podcastsCategories?.find((category) => category.nom === catName?.replaceAll('_', ' '))?.id;
			dispatch(getPodcastsGenre(catId, navigate));
			setMorePodcasts(false);
		};
		asyncProcess();
	}, [dispatch, catName, navigate, podcastsCategories]);

	useEffect(() => {
		const asyncProcess = () => {
			dispatch(getPodcastsCategories());
		};
		asyncProcess();
	}, [dispatch]);

	return (
		<div id="categoryPodcastPage">
			<Helmet>
				<title>{`Radioplayer : Radio & Podcast en streaming`}</title>
				<meta name={translate('descripton')} content={translate('description_all')} />
				<meta name="robots" content={'radio, streaming, podcast, gratuit, mp3, aac'} />
			</Helmet>
			<section className="section1">
				<Container>
					<Row>
						<Col md={{ span: 8, offset: 2 }} className="text-center">
							<h1>{translate('Vos podcasts préférés sont')}</h1>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="section2">
				<Container>
					<h2 className="ms-2">{translate('listingCatsTitle')}</h2>
					<div className="catsBlock-wrapper">
						<div className="catsBlock">
							{windowSize >= 769 ? (
								podcastsCategories.map((item) => (
									<PodcastCategory key={item?.id?.toString()} item={item} />
								))
							) : (
								<Slider ref={sliderRef} {...settings}>
									{podcastsCategories.map((item) => (
										<PodcastCategory key={item?.id?.toString()} item={item} />
									))}
								</Slider>
							)}
						</div>
					</div>
					<h2 className="podcastsBlockTitle ms-2">
						{translate('listingPodcastsTitle')} - {thisCategorie}
					</h2>
					<div className="podcastsBlock">
						{!morePodcasts ? (
							<PodcastItemCat items={podcastToShow} />
						) : (
							<PodcastItemCat items={genrePodcasts} />
						)}
					</div>
				</Container>
				<Row className="show-more-cat">
					{genrePodcasts.length > 25 && !morePodcasts ? (
						<Button variant="dark" className="show-more-cats-btn" onClick={() => setMorePodcasts(true)}>
							{translate('Afficher plus')}
						</Button>
					) : null}
				</Row>
			</section>
		</div>
	);
}
