import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SET_PLAYER } from '../../store/actions/constants';
import './style.css';
import { IoPlay } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading';

const { REACT_APP_DEFAULT_PODCAST_IMAGE } = process.env;

function PodcastItem({ podcast, isEpisode }) {
	const { playing, stream: playedPodcast } = useSelector((state) => state.player);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [invalid, setInvalid] = useState(false);

	const handleOnClick = (idseries) => {
		navigate(`/podcast/${idseries}`);
	};

	const handleOnPlay = () => {
		const payload = {
			playing: !(playing && playedPodcast.url === podcast.audio_url),
			stream: {
				nom: podcast.mediumName,
				description: podcast.longName,
				rpID: podcast.rpID,
				image: podcast.image,
				url: podcast.audio_url,
			},
			playedSeconds: 0,
			deltaTime: 0,
			userClick: true,
			isRadio: false,
		};

		dispatch({ type: SET_PLAYER, payload: payload });
	};
	return (
		<div
			key={podcast.id}
			className="responsive-image-container-top"
			onClick={() => {
				handleOnClick(podcast.idseries || podcast.feedID || podcast.id);
			}}
		>
			<Loading
				size={30}
				color="red"
				conClass="podcastAlauneLoading"
				style={{
					opacity: loading && !invalid ? 1 : 0,
					position: 'absolute',
					top: '50%',
					height: '30px',
					zIndex: '99',
				}}
			/>
			<img
				src={!invalid ? podcast.imageUrl || podcast.image : REACT_APP_DEFAULT_PODCAST_IMAGE}
				alt={`Slide ${podcast.seriesId}`}
				className="responsive-image-top"
				onLoad={() => setLoading(false)}
				onError={() => setInvalid(true)}
				style={{ opacity: invalid ? 0.7 : 1 }}
			/>
			{isEpisode && (
				<div className="podcastItemPlayBtnWrapper" variant="primary">
					<div
						className="podcastItemPlayBtn"
						onClick={() => {
							handleOnClick(podcast.idseries || podcast.feedID || podcast.id);
							isEpisode && handleOnPlay();
						}}
					>
						<IoPlay size={15} color="#000" />
					</div>
				</div>
			)}
			{invalid && <span className="podcastItemLegend">{podcast.title || podcast.mediumName}...</span>}
		</div>
	);
}

export default PodcastItem;
