import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SET_CURRENT_FEED } from '../../store/actions/constants';
import './style.css';
import moment from 'moment';

const FeedItem = ({ item }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const onClick = () => {
		dispatch({ type: SET_CURRENT_FEED, payload: item });
		navigate(`/article/${item.id}`);
	};

	return (
		<div onClick={onClick} className="feedItemCon">
			<div className="feedItemImage" style={{ backgroundImage: `url(${item?.image})` }} />
			<div className="feedItemContent">
				<h4 dangerouslySetInnerHTML={{ __html: item?.title }}></h4>
				<p className="feedItemDate">{`${moment(item?.date).format('DD MMM YYYY')}`}</p>
				<div className="desc" dangerouslySetInnerHTML={{ __html: item?.content.substr(0, 200) }}></div>
			</div>
		</div>
	);
};

export default FeedItem;
