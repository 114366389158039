import React, { useRef } from 'react';

const Pulse = () => {
	const pulseRef = useRef(null);

	return (
		<div ref={pulseRef} className="rpPulseCon">
			<div className="rpPulseDot" />
		</div>
	);
};

export default Pulse;
