import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import './style.css';
import { Helmet } from 'react-helmet';
import { scrollTop } from '../../helpers/behavior';

const Google = () => {
	useEffect(() => {
		scrollTop();
	}, []);

	return (
		<div id="footerPage">
			<Helmet>
				<title>{`Radioplayer : Radio & Podcast en streaming`}</title>
				<meta
					name="description"
					content="Retrouvez les radios françaises et les podcasts sur Radioplayer France. Plus de 800 radios et des milliers de podcasts à votre disposition."
				/>
				<meta name="robots" content={'radio, streaming, podcast, gratuit, mp3, aac'} />
			</Helmet>
			<div>
				<section className="section1">
					<Container>
						<h1>Radioplayer pour Google Assistant</h1>
					</Container>
				</section>
				<section className="section2">
					<Container>
						<h3>Dites simplement : hey Google parler avec Radioplayer</h3>
						<p>
							<strong>
								Les enceintes connectées sont très populaires et la radio est l’une des fonctionnalités
								les plus utilisées. Mais parfois, il peut être difficile de trouver la bonne station ou
								tout simplement d’écouter sa radio préférée. C’est pourquoi nous avons créé Radioplayer
								pour Google Assistant.
							</strong>
						</p>
						<p>
							Imaginons que vous vouliez que votre assistant vocal vous dise quelle chanson votre station
							diffuse, vous recommande une station locale ou vous aide simplement à trouver la bonne
							version régionale de votre station préférée… C’est pourquoi nous avons lancé Radioplayer
							pour Google Action. Ici, vous pouvez facilement trouver vos stations préférées en grande
							qualité, tout comme vous le faites avec Radioplayer sur d’autres appareils.
						</p>
						<p>
							Selon le pays dans lequel vous vous trouvez, vous trouverez une version locale de
							Radioplayer, parlant votre langue et proposant les stations que vous vous attendez à
							entendre là où vous habitez. La langue et le contenu de Radioplayer pour Google Assistant
							dépendront des paramètres de votre compte Google (veuillez en savoir plus ci-dessous).
						</p>
						<p>
							Le nouveau Radioplayer pour Google Assistant est actuellement disponible en France, Belgique
							et Norvège. D’autres pays suivront bientôt.
						</p>
						<p>
							La première fois que vous utilisez Radioplayer pour Google Assistant, vous devrez accepter
							certaines conditions pour vous assurer que nous pouvons vous offrir l’expérience radio la
							plus conviviale, mais soyez assuré que nous ne recueillons aucune information personnelle
							pour vous identifier.
						</p>
						<p>
							<strong>Hey Google, parler avec Radioplayer</strong>
						</p>
						<p>
							Lorsque vous vous êtes assuré que vos paramètres sont corrects, vous pouvez soit accéder à
							Radioplayer en disant “Hey Google, parler avec Radioplayer”, soit demander directement une
							station si vous savez déjà ce que vous voulez écouter, dites simplement “Hey Google ,
							demandez à Radioplayer de diffuser Radio X (le nom de la station que vous souhaitez
							écouter)”. Lorsque la station que vous souhaitez écouter est disponible en version locale,
							votre code postal vous sera demandé pour fournir la bonne version de votre radio.
						</p>
						<p>
							Une astuce ! Si vous écoutez habituellement la même station, vous pouvez simplement dire
							“Hey Google, parle avec Radioplayer”, et Radioplayer reprendra la lecture de votre dernière
							station écoutée.
						</p>
						<p>
							<strong>Google Home dans le salon</strong>
						</p>
						<p>
							<strong>Principales fonctionnalités et commandes</strong>
							<br />
							Voici les commandes les plus populaires que vous pouvez utiliser avec Radioplayer pour
							Google Assistant&nbsp;:
						</p>
						<ul>
							<li>
								Qu’est-ce que j’écoute? (Radioplayer vous indiquera le nom de la station, la chanson,
								l’artiste ou le programme que vous écoutez, selon ce que la radio en cours de lecture
								nous dit)
							</li>
							<li>Écouter une radio recommandée</li>
							<li>Ecouter une radio locale</li>
							<li>Jouer une radio tendance</li>
							<li>Jouer une radio au hasard</li>
							<li>Écoutez ma radio la plus écoutée</li>
							<li>Écouter ma dernière radio écoutée</li>
							<li>Aider</li>
						</ul>
						<p>
							Vous pouvez également utiliser directement toutes ces commandes Play, sans dire d’abord
							“parler avec Radioplayer”. Par exemple. si vous voulez que Radioplayer vous suggère une
							station, dites simplement “Hey Google, demande à Radioplayer de jouer une station
							recommandée”.
						</p>
						<p>
							<strong>Paramètres du compte Google</strong>
							<br />
							Les paramètres de langue de votre téléphone doivent également être choisis dans les
							paramètres de langue de l’application Google Assistant.
						</p>
						<p>
							Radioplayer essaie toujours de fournir la meilleure expérience utilisateur. Pour ce faire,
							nous avons besoin de votre emplacement pour recommander des stations locales et vous fournir
							la version correcte de toutes les stations avec des variations régionales. De plus, nous
							devons être en mesure de vous reconnaître en tant qu’utilisateur lorsque vous revenez à
							Radioplayer pour Google Assistant pour reprendre la lecture de vos dernières stations
							écoutées. Radioplayer ne stocke aucune donnée personnelle, le service se souvient uniquement
							des stations qui ont été jouées sur vos appareils. Si nous ne pouvons pas reconnaître votre
							compte Google, vous serez traité comme un nouvel utilisateur chaque fois que vous accéderez
							à Radioplayer. Par conséquent, pour obtenir la meilleure expérience utilisateur de
							Radioplayer sur Google Assistant, vous devez autoriser les éléments suivants dans les
							paramètres du compte Google&nbsp;:
						</p>
						<ul>
							<li>“Résultats personnels” doit être activé</li>
							<li>«&nbsp;Activité sur le Web et les applications&nbsp;» doit être activé</li>
						</ul>
						<p>
							Vous pouvez toujours modifier les autorisations, voir et supprimer vos données et trouver la
							politique de confidentialité de Radioplayer pour Google Action ici&nbsp;:
							assistant.google.com/explore.
						</p>
						<p>
							F<strong>AQ</strong>
						</p>
						<ul>
							<li>
								Pourquoi Radioplayer for Google Assistant me demande-t-il mon code postal/postal&nbsp;?
							</li>
						</ul>
						<p>
							Pour vous donner des recommandations locales, Radioplayer vous demandera la permission
							d’accéder à votre code postal. Le code postal qui sera utilisé est celui dans vos paramètres
							marqué comme «&nbsp;maison&nbsp;» dans votre application Google Assistant. Pour activer
							l’autorisation de localisation sur votre téléphone
						</p>
						<p>
							Démarrez Radioplayer, en disant ou en écrivant “parler avec Radioplayer”
							<br />
							Demandez à l’Action de “jouer une station locale”
							<br />
							Radioplayer vous demandera alors l’autorisation de votre code postal. Répondez par
							«&nbsp;Oui&nbsp;»
							<br />
							Ensuite, il vous sera peut-être demandé d’activer l’accès à la localisation. Veuillez
							cliquer sur le bouton «&nbsp;Paramètres de localisation&nbsp;» pour autoriser l’accès à
							votre emplacement
						</p>
						<ul>
							<li>
								J’entends le même message de bienvenue et les mêmes astuces à chaque fois que j’utilise
								Radioplayer pour Google Assistant
							</li>
						</ul>
						<p>
							Cela se produit lorsque nous ne pouvons pas vous reconnaître en tant qu’utilisateur. Pour
							nous, vous ressemblez à un utilisateur novice à chaque fois que vous utilisez Radioplayer.
							Nous vous donnerons l’introduction et des astuces comme si vous n’aviez jamais utilisé
							Radioplayer auparavant. Votre historique de lecture ne sera pas disponible et nous ne
							pourrons pas y accéder. A chaque invocation, vous recevrez un message comme celui-ci :
						</p>
						<p>
							“​​Veuillez garder à l’esprit&nbsp;: en raison de vos paramètres de confidentialité, je ne
							peux enregistrer aucune progression pour vous dans l’action Radioplayer. La prochaine fois
							que vous reviendrez, vos dernières modifications seront perdues et vous recommencerez.
							Veuillez modifier vos paramètres de confidentialité dans votre application Google Assistant
							pour me permettre d’enregistrer vos préférences. Vous trouverez des instructions détaillées
							sur notre site Web radioplayer.org/assistant.
						</p>
						<p>
							<em>
								Voici les explications et instructions de Google sur la façon de modifier ces
								paramètres&nbsp;:
							</em>
						</p>
						<p>
							Activer les résultats personnalisés
							<br />
							Activité Web et application activée. Gardez à l’esprit que certains utilisateurs peuvent
							avoir ce paramètre désactivé au niveau du domaine
							<br />
							Si vous avez activé Voice Match, cela doit être activé pour tous les appareils que vous
							utilisez avec Radioplayer
						</p>
						<ul>
							<li>Je n’arrive pas à faire fonctionner Radioplayer pour Google Assistant sur Sonos ?</li>
						</ul>
						<p>
							Ceci est malheureusement hors du contrôle de Radioplayer. Selon Google, AoG (Actions on
							Google) ne propose pas à ce jour de diffusion en direct sur des produits tiers tels que
							Sonos et autres.
						</p>
						<ul>
							<li>Comment puis-je supprimer mes données de Radioplayer pour Google Assistant&nbsp;?</li>
						</ul>
						<p>Démarrez Radioplayer, en disant ou en écrivant “parler avec Radioplayer”</p>
					</Container>
				</section>
			</div>
		</div>
	);
};

export default Google;
