import React from 'react';
import './style.css';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

/**
 * Bouton affichant une catégorie de radio
 *
 * OnClic : Redirection vers l'url de la catégorie
 *
 * @param {object} item category item
 * @param {boolean} selected isSelected
 * @returns JSX
 */
function PodcastCategory({ item, selected }) {
	const navigate = useNavigate();
	const catName = item.nom?.replaceAll(' ', '_');
	const onClick = () => {
		navigate(`/podcasts-category/${catName}`);
	};
	return (
		<Button
			variant={selected ? 'info' : 'primary'}
			className="podcastCategorie"
			key={item.id.toString()}
			onClick={onClick}
		>
			{item.nom}
		</Button>
	);
}

export default PodcastCategory;
