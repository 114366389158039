import React, { useEffect } from 'react';
import './style.css';
import { Button, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import translate from '../../translation';
import { scrollTop } from '../../helpers/behavior';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { getArticleById } from '../../store/actions/feed';

const Article = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { id } = useParams();
	const { currentFeed } = useSelector((state) => state.feed);

	useEffect(() => {
		scrollTop();
	}, []);

	useEffect(() => {
		dispatch(getArticleById(id));
	}, [dispatch, id]);

	const onClickBack = () => {
		navigate.goBack();
	};
	return (
		<div id="articlePage">
			<Helmet>
				<title>{`Radioplayer : Radio & Podcast en streaming`}</title>
				<meta name={translate('descripton')} content={translate('description_all')} />
				<meta name="robots" content={'radio, streaming, podcast, gratuit, mp3, aac'} />
			</Helmet>
			<section
				className="section1"
				style={{
					backgroundImage: `linear-gradient(to bottom , rgba(0,0,0,0) 10%, rgba(0,0,0,0.9) 70%),url(${currentFeed?.image})`,
				}}
			>
				<Container>
					<Button variant="link" onClick={onClickBack}>
						<span className="left-arrow"></span>
						{translate('Retour au actualités')}
					</Button>
					<h1 dangerouslySetInnerHTML={{ __html: currentFeed?.title }}></h1>
					<p>{`${translate('Publié le')} ${moment(currentFeed?.date).format('DD MMM YYYY')}`}</p>
				</Container>
			</section>
			<section className="section2">
				<Container>
					<div className="desc" dangerouslySetInnerHTML={{ __html: currentFeed?.content }}></div>
				</Container>
			</section>
		</div>
	);
};

export default Article;
