import React from 'react';
import './style.css';
import Slider from 'react-slick';
import { isMobile } from 'react-device-detect';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import PodcastItem from '../PodcastItem';

function PodcastsTopSlider({ podcasts, isEpisode }) {
	function SampleNextArrow(props) {
		const { className, style, onClick } = props;
		return (
			<IoChevronForwardOutline
				className={`${className} arrowTopSlider`}
				style={{
					...style,
					display: 'block',
					color: 'white',
					fontSize: '30px',
					zIndex: 1,
					height: '100%',
					borderRadius: '0 10px 10px 0',
					width: '50px',
					right: '0px',
				}}
				onClick={onClick}
			/>
		);
	}

	function SamplePrevArrow(props) {
		const { className, style, onClick } = props;
		return (
			<IoChevronBackOutline
				className={`${className} arrowTopSlider`}
				style={{
					...style,
					display: 'block',
					color: 'white',
					fontSize: '30px',
					zIndex: 2,
					height: '100%',
					borderRadius: '10px 0 0 10px',
					width: '50px',
					left: '3px',
				}}
				onClick={onClick}
			/>
		);
	}

	var settings = {
		dots: false,
		lazyLoad: true,
		infinite: true,
		slidesToShow: 6,
		cssEase: 'ease-out',
		slidesToScroll: 5,
		autoplay: false,
		autoplaySpeed: 500,
		draggable: isMobile,
		arrows: !isMobile,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		adaptiveHeight: true,
		responsive: [
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 1,
				},
			},
		],
	};

	return podcasts?.length > 0 ? (
		<div className="slider-container">
			<Slider {...settings}>
				{podcasts.map((item, index) => (
					<PodcastItem podcast={item} index={index} key={index} isEpisode={isEpisode} />
				))}
			</Slider>
		</div>
	) : (
		<div className="text-center">Chargement en cours...</div>
	);
}

export default PodcastsTopSlider;
