import React from 'react';
import PodcastSerie from '../../../components/PodcastSerie';

const Episodes = ({ episodes }) => {
	const podcastEpisodes = episodes.map(function (item, index) {
		return <PodcastSerie key={index} item={item} />;
	});
	return podcastEpisodes;
};

export default Episodes;
